import React from 'react'
import Home from './pages/Home'
import About from './pages/About'
import Courses from './pages/Courses'
// import Gallery from './pages/Gallery'
import ChargesList from './pages/Treatments/ChargesList'
import Treatments from './pages/Treatments/Treatments'
import Contact from './pages/Contact'
import Error from './pages/Error'
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import Appointment from './pages/Registration'
import Gallery from './pages/portfolio-gallery/Gallery'
import {HashRouter as Router, Routes,Route, } from 'react-router-dom'
import DocumentViewer from './pages/DocumentViewer'

const App = () => {
  return (
    <>
    <Router>
    <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="about-us" element={<About/>}/>
        <Route path="courses" element={<Courses/>}/>
        <Route path="service-charges" element={<ChargesList/>}/>
        <Route path="treatments" element={<Treatments/>}/>
        <Route path="gallery" element={<Gallery/>}/>
        <Route path="contact-us" element={<Contact/>} />
        <Route path="*" element={<Error/>}/>
        <Route path='/appointment' element={<Appointment/>}/>
        <Route path='/docViewer/:path' element={<DocumentViewer/>}/>
      
      </Routes>
    <Footer/>
    </Router>
    </>
  )
}

export default App