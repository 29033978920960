import React,{useState} from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useParams } from 'react-router-dom';


function DocumentViewer() {
    
    const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const {path} = useParams()

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);

  return (
    <>
    
<div className='text-center mt-5'>
				<button className="" onClick={goToPrevPage}>Prev</button>
				&nbsp;
				<button onClick={goToNextPage}>Next</button>
				<p>
					Page {pageNumber} of {numPages}
				</p>
			</div>

			<Document
				file={'pdf/'+path}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				<Page pageNumber={pageNumber} />
			</Document>
      
</>
  )
}

export default DocumentViewer