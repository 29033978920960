import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../assets/css/slider-animations.css';
import '../assets/css/style.css';

const content = [
	{
		title: 'Welcome to Dental Planet Multisolutions',
		description: 'Give Your Family Healthy Bright Teeth & Gums',
		button: 'Read More',
		image: 'images/slider/slide-7.jpg',
		// user: 'Luan Gjokaj',
		// userProfile: 'https://i.imgur.com/JSW6mEk.png'
	},
	{
		title: "Don't Hide Your Smile",
		description:'Smile Makeovers, Dental Implants, Teeth Whitening, Crowns / Veneers, Tooth Straightening',
		button: 'Discover',
		image: 'images/slider/slide-8.jpg',
		// user: 'Erich Behrens',
		// userProfile: 'https://i.imgur.com/0Clfnu7.png'
	},
	{
		title: 'Do you know why Dental Checkup are so Important',
		description:'Identify and remove early sing s of decay',
		button: 'Buy now',
		image: 'images/slider/slide-9.jpg',
		// user: 'Bruno Vizovskyy',
		// userProfile: 'https://i.imgur.com/4KeKvtH.png'
	}
];

function Banner() {
  return (
	<div>
	
		<Slider className="slider-wrapper">
			{content.map((item, index) => (
				<div
					key={index}
					className="slider-content"
					style={{ background: `url('${item.image}') no-repeat center center` }}
				>
					<div className="inner">
						<h1 class="steelblue-color">{item.title}</h1>
						<p>{item.description}</p>
						{/* <button>{item.button}</button> */}
					</div>
					{/* <section>
						<img src={item.userProfile} alt={item.user} />
						<span>
							Posted by <strong>{item.user}</strong>
						</span>
					</section> */}
				</div>
			))}
		</Slider>
	</div>
  )
}

export default Banner