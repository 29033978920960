import React from 'react'

const ChargesList = () => {
  return (
    <>
      <section id="pricing-3" className="wide-60 pricing-section division">
				<div className="container">	
					<div className="row d-flex align-items-center">

						<div className="col-lg-12">
							<div className="txt-block pc-30 mb-40 wow" data-wow-delay="0.4s">

								<h3 className="h3-md steelblue-color text-center">Charges List</h3>

								<div className="pricing-table mb-40 " style={{overflowX:'auto'}}>								
									<table className="table table-hover">
										<thead>
										    <tr>
										      	<th scope="col">S.No.</th>
										      	<th scope="col">Service</th>
										      	<th scope="col">Charitable</th>
												<th scope="col">Specialist</th>
										    </tr>
										</thead>
										<tbody>
										    <tr>
										      	<th scope="row">1</th>
										     	<td>Check Up</td>
										      	<td><span>100/- Per Appointment</span></td>
												<td><span>200/- Per Appointment</span></td>
										    </tr>
										    <tr>
										      	<th scope="row">2</th>
										      	<td>Chech Up (Specialist)</td>
										      	<td><span>300/-</span></td>
												<td><span>500/-</span></td>
										    </tr>
										    <tr>
										      	<th scope="row">3</th>
										      	<td>X-Ray</td>
										      	<td><span>100/-</span></td>
												<td><span>200/-</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">4</th>
										      	<td>Root Canal Anterior Tooth</td>
										      	<td><span>1500</span></td>
												<td><span>4000</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">5</th>
										      	<td>Root Canal Posterior Tooth</td>
										      	<td><span>2000/-</span></td>
												<td><span>5000/-</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">6</th>
										      	<td>Post - Obturation Restoration</td>
												<td><span>300/- (GIC) | 500/- (Composite)</span></td>
										      	<td><span>500/-</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">7</th>
										      	<td>Metal Crown</td>
												<td><span>1200/-</span></td>
										      	<td><span>1500/- </span></td>
										    </tr>
                        <tr >
										      	<th scope="row">8</th>
										      	<td>PFM Crown</td>
												<td><span>2000/-</span></td>
										      	<td><span>4000/-</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">9</th>
										      	<td>CAD CAM Crown</td>
										      	<td><span>3000/-</span></td>
												<td><span>5000/-</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">10</th>
										      	<td>Zirconia Crown</td>
										      	<td><span>5000/-</span></td>
												<td><span>10,000/-</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">11</th>
										      	<td>Scaling</td>
												<td><span>600/- Per Sitting</span></td>
										      	<td><span>1500/- Per Sitting</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">12</th>
										      	<td>Polishing</td>
												<td><span>200/-.</span></td>
										      	<td><span>500/-</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">13</th>
										      	<td>GIC Filling</td>
												<td><span>300/-</span></td>
										      	<td><span>600/- onwards</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">14</th>
										      	<td>Composite Filling</td>
												<td><span>600/-</span></td>
										      	<td><span>1000/- to 1,500/-</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">15</th>
										      	<td>RPD</td>
												<td><span>500/- + 200/- Per Tooth</span></td>
										      	<td><span>1500/- + 200/- Per Tooth</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">16</th>
										      	<td>Complete Denture</td>
												<td><span>6000/- Onwards</span></td>
										      	<td><span>10,000/- onwards</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">17</th>
										      	<td>Extraction</td>
												<td><span>300/- to 600/-</span></td>
										      	<td><span>1,000/- onwards</span></td>
										    </tr>
                        <tr >
										      	<th scope="row">18</th>
										      	<td>Surgical Extraction	</td>
												<td><span>1000/-</span></td>
										      	<td><span>3000/- onwards</span></td>
										    </tr>
                        <tr>
										      	<th scope="row">19</th>
										      	<td>Disimpaction</td>
										      	<td><span>2000/- Onwards</span></td>
												<td><span>5000/- Onwards</span></td>
										    </tr>
                        <tr>
										      	<th scope="row">20</th>
										      	<td>Laser (Minor Surgery)</td>
										      	<td><span>2500/-</span></td>
												<td><span>5000/-</span></td>
										    </tr>
                        <tr>
										      	<th scope="row">21</th>
										      	<td>Flap Surgery</td>
												<td><span>2,000/- Per Quadrant + Graft</span></td>
										      	<td><span>5,000/- Per Quadrant + Graft</span></td>
										    </tr>
                        <tr>
										      	<th scope="row">22</th>
										      	<td>Braces</td>
												<td><span>25,000/- onwards</span></td>
										      	<td><span>30,000/- onwards</span></td>
										    </tr>
                        <tr>
										      	<th scope="row">23</th>
										      	<td>Removable Orthdontic Appliance</td>
												<td><span>5000/- Onwards</span></td>
										      	<td><span>10,000/- Onwards</span></td>
										    </tr>
                        <tr>
										      	<th scope="row">24</th>
										      	<td>Fluoride Application</td>
										      	<td><span>500/- per sitting</span></td>
												<td><span>1,000/- per sitting</span></td>
										    </tr>
                        					<tr>
										      	<th scope="row">25</th>
										      	<td>Post & Core</td>
										      	<td><span>1500/-</span></td>
												<td><span>3000/-</span></td>
										    </tr>
 											
										</tbody>
									</table>
								</div>

							</div>
						</div>

					 {/* <div className="col-lg-6">
							<div className="pricing-img wow fadeInUp" data-wow-delay="0.6s">
								<img className="img-fluid" src="images/image-04.png" alt="pricing-image"/>
							</div>							
						</div> 
	 */}
					</div>
				</div>
			</section>	


      <section id="banner-6" className="bg-scroll banner-section division">
				<div className="container white-color">
			 		<div className="row d-flex align-items-center">

						<div className="col-lg-9 icon-xl">

							<span className="flaticon-072-hospital-5"></span>

							<div className="banner-txt">
								<h4 className="h4-lg">Are you searching for the best dental clinic in your town? We care about your health 24/7</h4>
								{/* <p className="p-md">Donec vel sapien augue integer urna vel turpis cursus porta luctus</p> */}
							</div>

						</div>

						<div className="col-lg-3 ">
							<div className="banner-btn text-right">
								<a href="/#/contact-us" className="btn btn-md btn-tra-white blue-hover">Get In Touch</a>
							</div>
						</div>	


			 		</div>
				</div>
			</section>
    </>
  )
}

export default ChargesList