import React,{useEffect} from 'react'

const Treatments = () => {

  useEffect(()=>{
    window.scroll(0,0);
  },[])
  return (
    <>
      <section id="tabs-2" className="wide-40 tabs-section division">
      <div className="container">	
        <div className="row">


          <div className="col-lg-4">
            <div id="tabs-nav" className="list-group text-center clearfix">

                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item icon-xs">
                    <a className="nav-link active" id="tab11-list" data-toggle="pill" href="#tab-11" role="tab" aria-controls="tab-11" aria-selected="true">
                      Teeth Cleaning
                    </a>
                  </li>

                <li className="nav-item icon-xs">
                    <a className="nav-link" id="tab12-list" data-toggle="pill" href="#tab-12" role="tab" aria-controls="tab-12" aria-selected="false">
                      Laser Dentistry
                    </a>
                </li>

                <li className="nav-item icon-xs">
                    <a className="nav-link" id="tab13-list" data-toggle="pill" href="#tab-13" role="tab" aria-controls="tab-13" aria-selected="false">
                      Dental Implants
                    </a>
                </li>

                <li className="nav-item icon-xs">
                    <a className="nav-link" id="tab14-list" data-toggle="pill" href="#tab-14" role="tab" aria-controls="tab-14" aria-selected="false">
                      Tooth Fillings
                    </a>
                </li>

                <li className="nav-item icon-xs">
                    <a className="nav-link" id="tab15-list" data-toggle="pill" href="#tab-15" role="tab" aria-controls="tab-15" aria-selected="false">
                      Tooth Replacement Solutions
                    </a>
                </li>

                <li className="nav-item icon-xs">
                    <a className="nav-link" id="tab16-list" data-toggle="pill" href="#tab-16" role="tab" aria-controls="tab-16" aria-selected="false">
                      Root Canal Treatment
                    </a>
                </li>

                <li className="nav-item icon-xs">
                    <a className="nav-link" id="tab17-list" data-toggle="pill" href="#tab-17" role="tab" aria-controls="tab-17" aria-selected="false">
                      Orthodontics
                    </a>
                </li>

                <li className="nav-item icon-xs">
                    <a className="nav-link" id="tab18-list" data-toggle="pill" href="#tab-18" role="tab" aria-controls="tab-18" aria-selected="false">
                      Oral Surgery
                    </a>
                </li>

              </ul>

            </div>	
          </div>

          <div className="col-lg-8">
            <div className="tab-content" id="pills-tabContent">

              <div className="tab-pane fade show active" id="tab-11" role="tabpanel" aria-labelledby="tab11-list">									

                <h3 className="h3-md steelblue-color">Teeth Cleaning</h3>

                <p>Teeth Cleaning is part of oral hygiene and involves the removal of dental plaque from teeth with
                  the intention of preventing cavities (dental caries), gingivitis, and periodontal disease. People
                  routinely clean their own teeth by brushing and interdental cleaning.

                </p>

                <div className="tab-img">
                  <video width="100%" height="350" controls src="video/Ultrasonic.mp4"></video>
                </div>
                
                {/* <p>An enim nullam tempor sapien gravida donec enim ipsum blandit
                  porta justo integer odio velna vitae auctor integer congue magna at pretium 
                  purus pretium ligula rutrum itae laoreet augue in cubilia laoreet an augue 
                  egestas ipsum vitae emo ligula vitae arcu mollis blandit ultrice ligula egestas 
                  magna suscipit
                </p> */}

                {/* <div className="row">

                  <div className="col-xl-6">

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Nemo ipsam egestas volute and turpis dolores quaerat massa suscipit, luctus neque</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>				
                    </div>

                  </div>

                  <div className="col-xl-6">

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                  </div>

                </div> */}

                {/* <a href="service-1.html" className="btn btn-blue blue-hover mt-30">View More Details</a> */}

              </div>

              <div className="tab-pane fade" id="tab-12" role="tabpanel" aria-labelledby="tab12-list">

                <h3 className="h3-md steelblue-color">Laser Dentistry</h3>

                <p>Laser Dentistry is the use of lasers to treat a number of different dental conditions. Laser
                    dentistry potentially offers a more comfortable treatment option for a number of dental
                    procedures involving hard or soft tissue compared to drills and other non-laser tools. Laser light
                    produces a reaction when it hits tissue, allowing it to remove or shape the tissue.
                </p>

                <div className="tab-img">
                  <video width="100%" height="350" controls src="video/laser.mp4"></video>
                  {/* <img className="img-fluid" src="images/gallery/ultraviolet_light_procedure.jpg" alt="tab-image" /> */}
                </div>

                {/* <p>An enim nullam tempor sapien gravida donec enim ipsum blandit
                  porta justo integer odio velna vitae auctor integer congue magna at pretium 
                  purus pretium ligula rutrum itae laoreet augue in cubilia laoreet an augue 
                  egestas ipsum vitae emo ligula vitae arcu mollis blandit ultrice ligula egestas 
                  magna suscipit
                </p>

                <div className="row">

                  <div className="col-xl-6">

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Nemo ipsam egestas volute and turpis dolores quaerat massa suscipit, luctus neque</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>				
                    </div>

                  </div>

                  <div className="col-xl-6">

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                  </div>

                </div>

                <a href="service-1.html" className="btn btn-blue blue-hover mt-30">View More Details</a> */}
                  
              </div>

              <div className="tab-pane fade" id="tab-13" role="tabpanel" aria-labelledby="tab13-list">

                <h3 className="h3-md steelblue-color">Dental Implants</h3>

                <p>Dental Implants are medical devices surgically implanted into the jaw to restore a person's
                  ability to chew or their appearance. They provide support for artificial (fake) teeth, such as
                  crowns, bridges, or dentures.
                </p>
                <div className="tab-img">
                  <video width="100%" height="350" controls src="video/DentalImplants.mp4"></video>
                </div>

                {/* <p>An enim nullam tempor sapien gravida donec enim ipsum blandit
                  porta justo integer odio velna vitae auctor integer congue magna at pretium 
                  purus pretium ligula rutrum itae laoreet augue in cubilia laoreet an augue 
                  egestas ipsum vitae emo ligula vitae arcu mollis blandit ultrice ligula egestas 
                  magna suscipit
                </p>

                <div className="row">

                  <div className="col-xl-6">

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Nemo ipsam egestas volute and turpis dolores quaerat massa suscipit, luctus neque</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>				
                    </div>

                  </div>

                  <div className="col-xl-6">

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                  </div>

                </div>

                <a href="service-1.html" className="btn btn-blue blue-hover mt-30">View More Details</a> */}
                  
              </div>

              <div className="tab-pane fade" id="tab-14" role="tabpanel" aria-labelledby="tab14-list">

                <h3 className="h3-md steelblue-color">Tooth Fillings</h3>

                <p>Tooth Fillings, or simply fillings are treatments used to restore the function, integrity, and
                    morphology of missing tooth structure resulting from caries or external trauma.
                </p>

                <div className="tab-img">
                  <div className='row'>
                    <video className="mb-4" width="100%" height="350" controls src="video/toothFilling.mp4"></video>
                    <div className='col-lg-6'><img className="img-fluid" src="images/gallery/restoration.jpg" alt="tab-image" /></div>
                    <div className='col-lg-6'><img className="img-fluid" src="images/gallery/restorative2.png" alt="tab-image" /></div>
                  </div>
                </div>

                {/* <p>An enim nullam tempor sapien gravida donec enim ipsum blandit
                  porta justo integer odio velna vitae auctor integer congue magna at pretium 
                  purus pretium ligula rutrum itae laoreet augue in cubilia laoreet an augue 
                  egestas ipsum vitae emo ligula vitae arcu mollis blandit ultrice ligula egestas 
                  magna suscipit
                </p>

                <div className="row">

                  <div className="col-xl-6">

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Nemo ipsam egestas volute and turpis dolores quaerat massa suscipit, luctus neque</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>				
                    </div>

                  </div>

                  <div className="col-xl-6">

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                  </div>

                </div>
              
                <a href="service-1.html" className="btn btn-blue blue-hover mt-30">View More Details</a> */}
                
              </div>


              <div className="tab-pane fade" id="tab-15" role="tabpanel" aria-labelledby="tab15-list">

                <h3 className="h3-md steelblue-color">Tooth Replacement Solutions</h3>

                <ol>
                  <li> 
                    <div className='row'>
                      <div className="col-lg-7 mb-4">
                    <p>Dental implants: are one of the most common methods of tooth replacement. Dental
                      implants are a very reliable type of tooth replacement that both looks and feels like a real
                      tooth. Dental implants provide a permanent solution if you need to replace a single tooth,
                      or multiple teeth, in different areas.</p>
                      </div>
                      <div className="col-lg-5 mb-4"><img className="img-fluid" src="images/gallery/dental-implants-1.jpg" alt="tab-image" /></div>
                    </div>
                  </li>
                  <li>
                  <div className='row'>
                      <div className="col-lg-7 mb-4">
                        <p>An implant-supported bridge: is an ideal solution for multiple missing teeth in a row. The
                        process of replacing each tooth with a dental implant can be very lengthy and
                        unnecessary. With an implant-supported bridge, only the teeth at the two ends are
                        secured in place with implants. The teeth in the middle of the two ends are held in place
                        without any screwing.</p>
                      </div>
                      <div className="col-lg-5"><img className="img-fluid" src="images/gallery/IMPLANT2.png" alt="tab-image" /></div>
                    </div>
                    
                  </li>
                  <li>
                  <div className='row'>
                      <div className="col-lg-7 mb-4">
                        <p> A tooth-supported bridge: uses your existing teeth to support the placement of a bridge,
                          rather than placing two implants into your teeth. A crown is placed on the teeth, which
                          are next to the missing ones and cemented into place. The procedure is completed over
                          a few visits.</p>
                      </div>
                      <div className="col-lg-5"><img className="img-fluid" src="images/gallery/TOOTHSUPPORTEDBRIGDE.jpg" alt="tab-image" /></div>

                    </div>
                    
                  </li>
                  <li>
                  <div className='row'>
                      <div className="col-lg-12 mb-4">
                        <p>Removable partial dentures: are a comparatively simpler option to replace missing teeth,
                          similar to dental implants. Compared to a complete set of dentures for all your teeth,
                          removable partial dentures are suggested for individuals with a few missing teeth.
                          Removable partial dentures are clasped into its correct place, which ensures that the
                          false teeth are held in the appropriate spots.</p>
                          <div className='row'>
                            <div className="col-lg-5"><img className="img-fluid" src="images/gallery/COMPLETEDENTURE.jpg" alt="tab-image" /></div>
                            <div className="col-lg-5"><img className="img-fluid" src="images/gallery/partial-dentures-2201.jpg" alt="tab-image" /></div>
                          </div>
                      </div>
                     
                      
                    </div>
                  </li>
                  <li>
                  <div className='row'>
                      <div className="col-lg-12 mb-4">
                        <p>Full mouth dental implants provide several advantages over other teeth replacement options like conventional dentures. In addition to looking and functioning like natural teeth, full mouth dental implants are designed to be long lasting. They’re also more comfortable and stable than conventional dentures, allowing you to bite and chew more naturally and to eat certain foods that can be difficult to eat with conventional dentures.</p>
                      </div>
                      <div className="col-lg-5"><img className="img-fluid" src="images/gallery/IMPLNT3.jpg" alt="tab-image" /></div>
                    </div>
                  </li>
                </ol>
              </div>


              <div className="tab-pane fade" id="tab-16" role="tabpanel" aria-labelledby="tab16-list">

                <h3 className="h3-md steelblue-color">Root Canal Treatment</h3>

                <p>Root Canal Treatment is designed to eliminate bacteria from the infected root canal, prevent
                    reinfection of the tooth and save the natural tooth. When one
                    undergoes a root canal, the inflamed or infected pulp is removed and the
                    inside of the tooth is carefully cleaned and disinfected, then filled and sealed.
                </p>

                <div className="tab-img">
                   <video width="100%" height="350" controls src="video/rootcanal.mp4"></video>
                </div>

                {/* <p>An enim nullam tempor sapien gravida donec enim ipsum blandit
                  porta justo integer odio velna vitae auctor integer congue magna at pretium 
                  purus pretium ligula rutrum itae laoreet augue in cubilia laoreet an augue 
                  egestas ipsum vitae emo ligula vitae arcu mollis blandit ultrice ligula egestas 
                  magna suscipit
                </p>

                <div className="row">

                  <div className="col-xl-6">

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Nemo ipsam egestas volute and turpis dolores quaerat massa suscipit, luctus neque</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>				
                    </div>

                  </div>

                  <div className="col-xl-6">

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                  </div>

                </div>

                <a href="service-1.html" className="btn btn-blue blue-hover mt-30">View More Details</a> */}
                  
              </div>


              <div className="tab-pane fade" id="tab-17" role="tabpanel" aria-labelledby="tab17-list">

                <h3 className="h3-md steelblue-color">Orthodontics</h3>

                <p>Orthodontics is a branch of dentistry that treats malocclusion, a condition in which the teeth are
                    not correctly positioned when the mouth is closed. This results in an improper bite.
                </p>

                <div className="tab-img">
                  <video width="100%" height="350" controls src="video/orthodontics.mp4"></video>
                </div>

                {/* <p>An enim nullam tempor sapien gravida donec enim ipsum blandit
                  porta justo integer odio velna vitae auctor integer congue magna at pretium 
                  purus pretium ligula rutrum itae laoreet augue in cubilia laoreet an augue 
                  egestas ipsum vitae emo ligula vitae arcu mollis blandit ultrice ligula egestas 
                  magna suscipit
                </p>

                <div className="row">

                  <div className="col-xl-6">

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Nemo ipsam egestas volute and turpis dolores quaerat massa suscipit, luctus neque</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>				
                    </div>

                  </div>

                  <div className="col-xl-6">

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                  </div>

                </div>

                <a href="service-1.html" className="btn btn-blue blue-hover mt-30">View More Details</a> */}
                  
              </div>

              <div className="tab-pane fade" id="tab-18" role="tabpanel" aria-labelledby="tab18-list">

                <h3 className="h3-md steelblue-color">Oral Surgery</h3>

                <p>Oral Surgery refers to any surgical procedure performed on your teeth, gums, jaws or other oral
                    structures. This includes extractions, implants, gum grafts and jaw surgeries.
                </p>

                <div className="tab-img">
                  <div className='row'>
                    <div className='col-lg-6'><img className="img-fluid" src="images/gallery/extraction2.jpg" alt="tab-image" /></div>
                    <div className='col-lg-6'><img className="img-fluid" src="images/gallery/extraction1.webp" alt="tab-image" /></div>
                  </div>
                </div>

                {/* <p>An enim nullam tempor sapien gravida donec enim ipsum blandit
                  porta justo integer odio velna vitae auctor integer congue magna at pretium 
                  purus pretium ligula rutrum itae laoreet augue in cubilia laoreet an augue 
                  egestas ipsum vitae emo ligula vitae arcu mollis blandit ultrice ligula egestas 
                  magna suscipit
                </p>

                <div className="row">

                  <div className="col-xl-6">

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Nemo ipsam egestas volute and turpis dolores quaerat massa suscipit, luctus neque</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>				
                    </div>

                  </div>

                  <div className="col-xl-6">

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                    <div className="box-list">							
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>An enim nullam tempor at pretium blandit</p>							
                    </div>

                    <div className="box-list">	
                      <div className="box-list-icon blue-color"><i className="fas fa-angle-double-right"></i></div>
                      <p>Magna massa suscipit, luctus neque purus and ipsum neque dolor primis luctus tempor</p>			
                    </div>

                  </div>

                </div>

                <a href="service-1.html" className="btn btn-blue blue-hover mt-30">View More Details</a> */}
                  
              </div>


            </div>


          </div>	
        </div>
      </div>
      </section>
</>
  )
}

export default Treatments