import React from 'react'
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { BsTwitter, BsInstagram } from "react-icons/bs";

function Footer() {
  return (
    <>
        <footer id="footer-2" className="wide-20 footer division" >
				<div className="container-fluid">

					<div className="row">	

						<div className="col-md-6 col-lg-3">
							<div className="footer-info ">

								<img src="images/logo-dental.png" alt="footer-logo"/> 
							</div>		
						</div>

						<div className="col-md-6 col-lg-3">
							<div className="footer-box mb-40">
												
								<h5 className="h5-xs">About Us</h5>

								<p className="p-sm mt-15">Dental Planet Multisolutions is an initiative by well qualified and highly 
								experienced team of dental surgeons covering all streams of dentistry.
								</p> 
								<div className="footer-socials-links mt-20">
									<ul className="foo-socials text-center clearfix">

										<li><a href="https://www.facebook.com/dentalplanetmultisolutions/" className="ico-facebook"><FaFacebookF className="icons"/></a></li>
										<li><a href="https://twitter.com/DMultisolutions" className="ico-twitter"><BsTwitter className="icons"/></a></li>	
										<li><a href="https://www.instagram.com/dentalplanetmultisolutions/" className="ico-google-plus"><BsInstagram className="icons"/></a></li>
										<li><a href="https://www.linkedin.com/in/dental-planet-multisolutions-a9a835178/" className="ico-tumblr"><FaLinkedinIn className="icons"/></a></li>
									</ul>									
								</div>																													

							</div>
						</div>	

						<div className="col-md-6 col-lg-3">
							<div className="footer-box mb-40">
							
								<h5 className="h5-xs">Contact Us</h5>

								<p>506-A, Corporate House, Block-A,</p> 
								<p>Near Central Mall, RNT Marg, </p>
								<p>Indore-452001</p>
								
								<p className="foo-email mt-0"><a href="mailto:dentalplanetmultisolutions@gmail.com">dentalplanetmultisolutions@gmail.com</a></p>

								

							</div>
						</div>

						<div className="col-md-6 col-lg-3">
							<div className="footer-box mb-40">

								<h5 className="h5-xs">Working Time</h5>

								<p className="p-sm">Mon - Sat - <span>10:00 AM - 7:00 PM</span></p>
								{/* <p className="p-sm">Tuesday - <span>10:00 AM - 7:00 PM</span></p>
								<p className="p-sm">Wednesday - <span>10:00 AM - 7:00 PM</span></p>
								<p className="p-sm">Thursday - <span>10:00 AM - 7:00 PM</span></p>
								<p className="p-sm">Friday - <span>10:00 AM - 7:00 PM</span></p>
								<p className="p-sm">Saturday - <span>10:00 AM - 7:00 PM</span></p> */}
								<p className="p-sm">Sunday - <span>By Appointment</span></p>										
								<p className="foo-email mt-0"><a href="tel:07314900922">0731-4900922</a></p>										
								<p className="foo-email mt-0"><a href="tel:+917974303995">+91 7974303995</a></p>
							</div>
						</div>

					</div>

					<div className="bottom-footer">
						<div className="row">
							<div className="col-md-12">
								<p className="footer-copyright text-center">&copy; 2003-2024 <span>dentalplanetms</span>. All Rights Reserved</p>
							</div>
						</div>
					</div>


				</div>								
			</footer>
    </>
  )
}

export default Footer