import React from 'react'

function Items({data}){
    return (
        <div className="row ">
        {
          data.map((value)=>{
           const {id, image, title, description} = value;
           return (
            <div className="col-sm-4 my-3 gallery-item" key={id}>
             <div className="card bg-light text-center">

             <div className="hover-overlay"> 

                <img src={image} className="img-fluid" style={{height:"200px"}}/>		
                <div className="item-overlay"></div>				

                <div className="image-zoom">
                  <a className="image-link" href={image} title=""><i className="fas fa-search-plus"></i></a>
                </div> 

            </div>

            </div>
         </div>
           )
          })
        }

</div>
    )
}

export default Items;