import React from 'react'

const Error = () => {
  return (
    <>
    <div className='text-center py-5 my-5 border-bottom'>
        <h1 style={{fontSize:"65px"}}>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </>
  )
}

export default Error