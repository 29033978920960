import React from 'react'
import { Link } from 'react-router-dom'

const Courses = () => {

	
  return (
    <>
		<section id="pricing-1" className="bg-lightgrey wide-60 pricing-section division">
				<div className="container">	

				{/* <div className="row">	
						<div className="col-lg-10 offset-lg-1 section-title">	

							<h3 className="h3-md steelblue-color">Intership </h3>	

						</div>
				</div>

				<div className="row pricing-row justify-content-center">

					<div className="col-lg-4">
						<div className="pricing-table icon-xl">	
							<img className="img-fluid pb-3" src="images/courses/internship.png" alt="tab-image" />
							<h4 className="pb-3">Internship Program</h4>
							<div className="row justify-content-around">
								<Link to={`/docViewer/CLINICALASSOCIATEPROGRAM.pdf`} className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
								<a href="pdf/CLINICALASSOCIATEPROGRAM.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
							</div>
						</div>
					</div>
					<div className="col-lg-4"></div>
					<div className="col-lg-4"></div>
				</div> */}

				<div className="row">	
						<div className="col-lg-10 offset-lg-1 section-title">	

							<h3 className="h3-md steelblue-color">Our Courses</h3>	

						</div>
					</div>

					<div className="row pricing-row justify-content-center">

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/courses/esthetic.png" alt="tab-image" />
								<h4 className="pb-3">Esthetic dentistry</h4>
								<div className="row justify-content-around">
									<Link to={`/docViewer/Estheticdentistry.pdf`} className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/Estheticdentistry.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/endodonticsCons.png" alt="tab-image" />
								 <h4 className="pb-3">Endodontics</h4>
								{/*<p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/EndodonticsCon.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/EndodonticsCon.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/prosthonticsOver.png" alt="tab-image" />
								<h4 className="pb-3">Prosthodontics</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/ProsthodonticsOverview.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/ProsthodonticsOverview.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/atozOral.png" alt="tab-image" />
								<h4 className="pb-3">A - Z Oral Implantology</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/OralImplantology.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/OralImplantology.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/periodonticsOver.png" alt="tab-image" />
								<h4 className="pb-3">Periodontics</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/PeriodonticsOverview.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/PeriodonticsOverview.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/oralSurgery.png" alt="tab-image" />
								<h4 className="pb-3">Oral Surgery</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/OralSurgeryOverview.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/OralSurgeryOverview.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/orthodonticsOver.png" alt="tab-image" />
								<h4 className="pb-3">Orthodontics</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/OrthodonticsOverView.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/OrthodonticsOverView.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/Laser.png" alt="tab-image" />
								<h4 className="pb-3">Lasers in Clinical Dentistry</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/Lasers.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/Lasers.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/Facial.png" alt="tab-image" />
								<h4 className="pb-3">Facial Aesthetics Workshop</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/FacialAestheticsWorkshop.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/FacialAestheticsWorkshop.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/BDSIntern.png" alt="tab-image" />
								<h4 className="pb-3">BDS Internship Programme</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/BDSInternshiProgramme.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/BDSInternshiProgramme.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								{/* <span className="flaticon-104-blood-sample blue-color"></span>	 */}
								<img className="img-fluid pb-3" src="images/courses/FinalYBDS.png" alt="tab-image" />
								<h4 className="pb-3">Final Year BDS Programme</h4>
								{/* <p>Academy for Clinical Excellence<br/>
									(A unit of Dental Planet Multi Solutions)</p> */}

								<div className="row justify-content-around">
									<Link to="/docViewer/FinalYearBDSProgramme.pdf" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="pdf/FinalYearBDSProgramme.pdf" download className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>


		
					</div>
				</div>	
			</section>

			{/* <section id="pricing-1" className="bg-lightgrey  pricing-section division">
				<div className="container">	


				<div className="row">	
						<div className="col-lg-10 offset-lg-1 section-title">	

							<h3 className="h3-md steelblue-color">Our Courses</h3>	

						</div>
					</div>


					<div className="row pricing-row">

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Endodontics.png" alt="tab-image" />
								<h4 className="pb-3">Endodontics Conservative</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Esthetic.png" alt="tab-image" />
								<h4 className="pb-3">Esthetic Dentistry</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Oral.png" alt="tab-image" />
								<h4 className="pb-3">Oral Surgery</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Orthodontics.png" alt="tab-image" />
								<h4 className="pb-3">Orthodontics</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Periodontics.png" alt="tab-image" />
								<h4 className="pb-3">Periodontics (Advanced C.)</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Periodontics+surgical.png" alt="tab-image" />
								<h4>Periodontics (Basic C.)</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Prosthodontics.png" alt="tab-image" />
								<h4 className="pb-3">Prosthodontics</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Facial.png" alt="tab-image" />
								<h4 className="pb-3">Facial Aesthetics Workshop</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Oral-implantology.png" alt="tab-image" />
								<h4 className="pb-3">A - Z of Oral Implantology</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/Laser.png" alt="tab-image" />
								<h4 className="pb-3">Lasers in Clinical Dentistry</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/bds.png" alt="tab-image" />
								<h4 className="pb-3">Final Year BDS Programme</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="pricing-table icon-xl">	
								<img className="img-fluid pb-3" src="images/gallery/bdsIntern.png" alt="tab-image" />
								<h4 className="pb-3">Final Year BDS Programme</h4>
								<div className="row justify-content-around">
									<Link to="/" className="btn btn-md btn-pdf btn-tra-black blue-hover">View PDF</Link>
									<a href="#" className="btn btn-md btn-pdf btn-tra-black blue-hover">Download PDF</a>
								</div>
							</div>
						</div>


						
					</div>

				</div>	
			</section> */}
	
	</>
  )
}

export default Courses