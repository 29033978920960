import React from 'react'

const About = () => {
  return (
    <>

<section id="about-6" className="pt-100 about-section division">
				<div className="container">
					<div className="row d-flex align-items-center">

						<div className="col-lg-8">
							<div className="txt-block wow fadeInUp" data-wow-delay="0.4s">

					 			<span className="section-id blue-color">MD & CEO</span>

								<h3 className="h3-md steelblue-color">Dr. Priyank Banthia</h3>

								<div className="box-list">							
									<div className="box-list-icon"><i className="fas fa-genderless"></i></div>
									<p>Dr. Priyank Banthia was born in Kolkata in 1974 but moved to New Delhi as a one year old. He did his schooling from Ramjas 
                    school and Sardar Patel Vidyalaya wherein he was inculcated the best of leadership qualities.
									</p>							
								</div>

								<div className="box-list">	
									<div className="box-list-icon"><i className="fas fa-genderless"></i></div>
									<p>Dr. Priyank Banthia completed his graduation from the prestigious Maulana Azad Medical college in 1998. After one year 
                  of junior residency in Lok Nayak Hospital in New Delhi, he completed post graduation in Periodontogy from Punjab Government 
                  Dental College and Hospital, Amritsar in 2002. Maintaining a perfect balance between academics and clinical practice, 
                  Dr. Priyank has constantly strived to uphold the dignity of dental profession by inculcating the right attitude in his 
                  students and associates. Till recently he was working as Dean in Index Institute of Dental Sciences, Indore while maintaining
                   a successful practice in Indore and Delhi NCR.
									</p>
                  <p>His areas of interest include Periodontics, Laser and Implant Dentistry as can be deciphered by the fellowship
                   he pursued from DY Patil University, Navi Mumbai. He is currently undergoing training in Hospital Management
                    from National Institute of Health and Family Welfare, New Delhi so as to better administer his company.
                </p>		
								</div>

								<a href="/#/appointment" className="btn btn-blue blue-hover mt-25">Meet The Doctors</a>

							</div>
						</div>	

						<div className="col-lg-4">
							<div className="about-img text-center wow fadeInUp" data-wow-delay="0.6s">
								<img className="img-fluid" src="images/dr.png" alt="about-image"/>
							</div>
						</div>

						
					</div>
				</div>
			</section>

      <section id="about-6" className="pt-100 about-section division wide-60">
				<div className="container">
					<div className="row d-flex align-items-center">

          <div className="col-lg-4">
							<div className="about-img text-center wow fadeInUp" data-wow-delay="0.6s">
								<img className="img-fluid" src="images/Dr.-Ruchi-Banthia.jpg" alt="about-image"/>
							</div>
						</div>

						<div className="col-lg-8">
							<div className="txt-block wow fadeInUp" data-wow-delay="0.4s">

					 			<span className="section-id blue-color">Director</span>

								<h3 className="h3-md steelblue-color">Dr. Ruchi Banthia </h3>

								<div className="box-list">							
									<div className="box-list-icon"><i className="fas fa-genderless"></i></div>
									<p>Dr. Ruchi Banthia was born in Ludhiana, Punjab in 1976. Embodying the industrious and never-say-die attitude of Punjabis, she scored highly in school and continued the same success story in her college days. She was a Gold medalist from Punjab Government Dental College and Hospital, 
                    Amritsar in 1998 and went on to pursue post graduation in Periodontics from the same institute.
									</p>							
								</div>

								<div className="box-list">	
									<div className="box-list-icon"><i className="fas fa-genderless"></i></div>
									<p>Since 2001, she has worked with prestigious institutions like SGRD, Amritsar and DJ Dental College, Modinagar. 
                    Currently she is working as a Professor in Modern Dental College and Research Centre, Indore for the past 14 years.
									</p>
								</div>

								<a href="/#/appointment" className="btn btn-blue blue-hover mt-25">Meet The Doctors</a>

							</div>
						</div>	
	
					</div>
				</div>
			</section>


      <section id="banner-8" className="bg-fixed banner-section division">
				<div className="container white-color">
			 		<div className="row d-flex align-items-center">

						<div className="col-lg-8 offset-lg-2">
							<div className="banner-txt icon-lg text-center">

								<span className="flaticon-072-hospital-5"></span>

								<h3 className="h3-sm">Take the First Step to Help</h3>
								
							    <h4 className="h4-lg">Call Dental Planets Multisolutions Now</h4>
							    <h2 className="h2-lg"><a href="tel:07314900922">0731-4900922</a></h2>

							</div>
						</div>


			 		</div>
				</div>
			</section>
    </>
  )
}

export default About