const data =[
    {
        id:1,
        image:"../../images/portfolio/1.jpg",
        // title:"HTML Tutoril",
        // description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"Academy Training",
       
    },
    {
        id:2,
        image:"../../images/portfolio/2.jpg",
        category:"Academy Training",
       
    },
    {
        id:3,
        image:"../../images/portfolio/3.jpg",
        category:"Academy Training",
       
    },
    {
        id:4,
        image:"../../images/portfolio/4.jpg",
        category:"Academy Training",
       
    },
    {
        id:5,
        image:"../../images/portfolio/5.jpg",
        category:"Academy Training",
       
    },
    {
        id:6,
        image:"../../images/portfolio/6.jpg",
        category:"Academy Training",
       
    },
    {
        id:7,
        image:"../../images/portfolio/7.jpg",
        category:"Academy Training",
       
    },
    {
        id:8,
        image:"../../images/portfolio/8.jpg",
        category:"Academy Training",
       
    },
    {
        id:9,
        image:"../../images/portfolio/12.jpg",
        category:"Academy Training",
       
    },
    {
        id:10,
        image:"../../images/portfolio/10.jpg",
        category:"Academy Training",
       
    },
    {
        id:11,
        image:"../../images/camp/1.jpg",
        category:"Dental Camps",
       
    },
    {
        id:12,
        image:"../../images/camp/2.jpg",
        category:"Dental Camps",
       
    },
    {
        id:13,
        image:"../../images/camp/3.jpg",
        category:"Dental Camps",
       
    },
    {
        id:14,
        image:"../../images/camp/4.jpg",
        category:"Dental Camps",
       
    },
    {
        id:15,
        image:"../../images/camp/5.jpg",
        category:"Dental Camps",
       
    },
    {
        id:16,
        image:"../../images/camp/6.jpg",
        category:"Dental Camps",
       
    },
    {
        id:17,
        image:"../../images/camp/7.jpg",
        category:"Dental Camps",
       
    },
    {
        id:18,
        image:"../../images/camp/8.jpg",
        category:"Dental Camps",
       
    },
    {
        id:19,
        image:"../../images/camp/9.jpg",
        category:"Dental Camps",
       
    },
    {
        id:20,
        image:"../../images/camp/10.jpg",
        category:"Dental Camps",
       
    },
    {
        id:21,
        image:"../../images/camp/11.jpg",
        category:"Dental Camps",
       
    },
    {
        id:22,
        image:"../../images/camp/12.jpg",
        category:"Dental Camps",
       
    },
    {
        id:23,
        image:"../../images/camp/13.jpg",
        category:"Dental Camps",
       
    }, {
        id:24,
        image:"../../images/camp/14.jpg",
        category:"Dental Camps",
       
    },
    {
        id:25,
        image:"../../images/camp/15.jpg",
        category:"Dental Camps",
       
    },

     {
        id:26,
        image:"../../images/camp/16.jpg",
        category:"Dental Camps",
       
    },
    {
        id:27,
        image:"../../images/camp/17.jpg",
        category:"Dental Camps",
       
    },
    {
        id:28,
        image:"../../images/camp/18.jpg",
        category:"Dental Camps",
       
    },
    {
        id:29,
        image:"../../images/camp/19.jpg",
        category:"Dental Camps",
       
    },
    {
        id:30,
        image:"../../images/event/1.jpg",
        category:"Events",
       
    },

    {
        id:31,
        image:"../../images/event/2.jpg",
        category:"Events",
       
    },
]
export default data;