import React from 'react'
import styled from 'styled-components';

const Button = styled.button`

cursor: default;
outline: 0px none;

outline-primary:not(:disabled):not(.disabled):active{
    border-bottom: 2px solid #00a3c8;
}
`;

function Tabs({filterCategory, tabsData}){
    return(
        <>
 <div className="text-center my-4">
 {
 tabsData.map((category, index)=>{
      return (
        <Button type="button" className="btn mx-2 text-capitalize btn-blue blue-hover mt-25" onClick={()=> filterCategory(category)} key={index}>{category}</Button>
      )
 })
 }
</div>
        </>
    )
}
export default Tabs;