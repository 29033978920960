import React,{useEffect} from 'react'

function Registration() {

	useEffect(()=>{
		window.scroll(0,0);
	  },[])

  return (
    <>
        <div id="appointment-page" className="wide-60 appointment-page-section division">
				<div className="container">
				 	<div className="row">

				 		<div className="col-lg-6">
				 			<div className="txt-block pr-30">

								<h3 className="h3-md steelblue-color">Why Us ?</h3>

								<p>If you are looking for ethics, trust and sincerity in our profession, your search ends here.</p>
                          
                                    <li>Dental Planet Multisolutions has super-specialty dental clinics with an efficient and skillful team of dentists, implantologists, and paradental staff.</li>
                                    <li> Easily accessable services are available with affordable pricing.</li>
                                    <li>You can locate your nearby accredited dental clinic.</li>
                                    <li>Treatments by utilizing latest technology.</li>
                                    <li>State-of-the-art sterilization protocols.</li>

                                <p> With our proactive mutualistic approach, we provide credible innovative solutions to 
                                everything relevant to the dental profession.</p>

                                <p>We are the DENTAL PLANET MULTISOLUTIONS.</p>

								

								{/* <p className="p-sm grey-color mb-30">* Porta semper lacus cursus, feugiat primis ultrice in ligula risus
								   auctor tempus feugiat dolor lacinia cubilia curae integer congue leo metus, primis in orci 
								   integer metus mollis faucibus enim. Nemo ipsam egestas volute turpis dolores ut aliquam quaerat
								   sodales sapien undo pretium purus
								</p> */}
								
				 				
				 			</div>
				 		</div>

					 <aside id="sidebar" className="col-lg-6">
						
                     <div id="appointment-form-holder" className="text-center">
									<form name="appointmentform" className="row appointment-form">

						                <div id="input-department" className="col-md-12 input-department">
						                    <select id="inlineFormCustomSelect1" name="department" className="custom-select department" required>
						                        <option value="">Select Service</option>  
						                      	<option>lopa Digital X-ray</option>
						                      	<option>Scaling	</option>
						                      	<option>Root Canal Treatment	</option>
						                      	<option>Post Obturation Restoration	</option>
						                      	<option>Post and Core	</option>
						                      	<option>Re-RCT	</option>
						                      	<option>Composite Restoration</option>
						                      	<option>GIC Restoration	</option>
												<option>Dental Implants</option>
												<option>Pediatric Treatment	</option>
												<option>Bleaching</option>
												<option>Fluoride Application</option>
												<option>Pediatric Treatment	</option>
												<option>Laser Curettage	</option>
												<option>Splinting</option>
												<option>Surgical Extraction	</option>
						                    </select>
						                </div>

						                {/* <div id="input-doctor" className="col-md-12 input-doctor">
						                    <select id="inlineFormCustomSelect2" name="doctor" className="custom-select doctor" required>
						                        <option value="">Select Doctor</option>  
						                      	<option>Jonathan Barnes D.M.</option>
						                      	<option>Hannah Harper D.M.</option>
						                      	<option>Matthew Anderson D.M.</option>
						                      	<option>Megan Coleman D.M.</option>
						                      	<option>Joshua Elledge D.M.</option>
						                      	<option>Other</option>
						                    </select>
						                </div> */}

						                <div id="input-patient" className="col-md-12 input-patient">
						                    <select id="inlineFormCustomSelect3" name="patient" className="custom-select patient" required>
						                        <option value="">Have You Visited Us Before?*</option>
												<option>New Patient</option>
												<option>Returning Patient</option>
												<option>Other</option>
						                    </select>
						                </div>

						                <div id="input-date" className="col-lg-12">
						                	<input id="datetimepicker" type="text" name="date" className="form-control date" placeholder="Appointment Date" required/> 
						                </div>
				                                            
						                <div id="input-name" className="col-lg-12">
						                	<input type="text" name="name" className="form-control name" placeholder="Enter Your Name*" required/> 
						                </div>
						                        
						                <div id="input-email" className="col-lg-12">
						                	<input type="text" name="email" className="form-control email" placeholder="Enter Your Email*" required/> 
						                </div>

						                <div id="input-phone" className="col-lg-12">
						                	<input type="tel" name="phone" className="form-control phone" placeholder="Enter Your Phone Number*" required/> 
						                </div>						                          
						                        
						                <div id="input-message" className="col-lg-12 input-message">
						                	<textarea className="form-control message" name="message" rows="6" placeholder="Your Message ..."></textarea>
						                </div> 

						                <div className="col-lg-12 form-btn">  
						                	<button type="submit" className="btn btn-blue blue-hover submit">Request an Appointment</button> 
						                </div>

						                <div className="col-lg-12 appointment-form-msg text-center">
						                	<div className="sending-msg"><span className="loading"></span></div>
						                </div>  
					                                              
					                </form> 

								</div>

						</aside> 

					</div>
				</div>
			</div>
    </>
  )
}

export default Registration