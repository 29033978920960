import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Link } from 'react-router-dom';
import Banner from '../components/Banner';



const arrOfImages = [
	"images/gallery/1.jpg",
	"images/gallery/2.jpg",
	"images/gallery/3.jpg",
	"images/gallery/4.jpg",
	"images/gallery/5.jpg",
	"images/gallery/6.jpg",
	"images/gallery/7.jpg",
	"images/gallery/8.jpg",
  ];
  

const Home = () => {

	const [indexOfImages, setIndexOfImages] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const openModalAndSetIndex = (index) => {
	  setIndexOfImages(index);
	  setShowModal(false);
	  return;
	};

  return (
    <>
		
<Banner/>

{/* <section id="about-4" className="wide-60 about-section division">
				<div className="container">
					<div className="row">

						<div className="col-lg-4">
							<div className="abox-4 mb-40 wow fadeInUp" data-wow-delay="0.4s">

								<img className="img-fluid" src="images/kids_dentistry_800x600.jpg" alt="about-image" />	
									
								<div className="abox-4-txt">

									<h5 className="h5-xs steelblue-color"><a href="all-services.html">Highest Quality Care</a></h5>

									<p>Porta semper lacus cursus, feugiat primis ultrice ligula risus auctor tempus feugiat
									   dolor lacinia cursus nulla vitae massa placerat at neque purus ultrice
									</p>
								</div>

							</div>
						</div>

						<div className="col-lg-4">
							<div className="abox-4 mb-40 wow fadeInUp" data-wow-delay="0.6s">

								<img className="img-fluid" src="images/dental_emergency_800x600.jpg" alt="about-image" />	
									
								<div className="abox-4-txt">

									<h5 className="h5-xs steelblue-color"><a href="all-services.html">Dental Emergency</a></h5>

									<p>Porta semper lacus cursus, feugiat primis ultrice ligula risus auctor tempus feugiat
									   dolor lacinia cursus nulla vitae massa placerat at neque purus ultrice
									</p>
								</div>

							</div>
						</div>

						<div className="col-lg-4">
							<div className="abox-4 abox-4-table blue-table mb-40 wow fadeInUp" data-wow-delay="0.6s">

								<h5 className="h5-sm">Working Time</h5>

								<p>Porta semper lacus cursus, feugiat primis ultrice ligula risus auctor at tempus feugiat
								   dolor lacinia cursus nulla vitae massa
								</p>

								<table className="table">
									<tbody>
									    <tr>
									      	<td>Mon – Wed</td>
									      	<td> - </td>
									      	<td className="text-right">9:00 AM - 7:00 PM</td>
									    </tr>
									    <tr>
									      	<td>Thursday</td>
									      	<td> - </td>
									      	<td className="text-right">9:00 AM - 7:00 PM</td>
									    </tr>
									     <tr>
									      	<td>Friday</td>
									      	<td> - </td>
									      	<td className="text-right">9:00 AM - 7:00 PM</td>
									    </tr>
									    <tr className="last-tr">
									      	<td>Sun - Sun</td>
									      	<td>-</td>
									      	<td className="text-right">Closed</td>
									   	 </tr>
									  </tbody>
								</table>

							</div>
						</div>


					</div>
				</div>
</section> */}


<section id="info-2" className="wide-60 info-section division">
				<div className="container">
					<div className="row d-flex align-items-center">

          
						<div className="col-lg-6">
							<div className="txt-block pc-30 mb-40 wow fadeInUp" data-wow-delay="0.4s">

					 			{/* <span className="section-id blue-color">Best Practices</span> */}

								<h3 className="h3-md steelblue-color">About Us</h3>

								<p className="mb-30">Dental Planet Multisolutions is an initiative by well qualified and highly experienced team of dental surgeons covering all streams of dentistry.

At Dental Planet Multisolutions, we are committed to provide you with the highest levels of dental services and care. Our highly qualified team of dental surgeons is committed to continuous training and upgradation to provide you with the latest in general and specialized treatments.

We aim to offer best dental practices in your neighbourhood. We are located at multiple prime locations like Vijay Nagar, Manik Bagh, RNT Marg, Kanchan Bag, Annapurna Road, Nipania, Khandwa Road (Shree Krishna Avenue), Chandan Nagar ... & counting.
								</p>
								
							</div>
						</div>



						<div className="col-lg-6">
							<div className="info-2-img wow fadeInUp" data-wow-delay="0.6s">
								<img className="img-fluid" src="images/about-2.png" alt="info-image"/>
							</div>
						</div>

						
					</div>
				</div>
</section>


<section id="services-6" className="bg-blue wide-40 servicess-section division">
		<div className="container white-color">	

			<div className="row">	
				<div className="col-lg-10 offset-lg-1 section-title">		

					<h3 className="h3-md">Our Core Dental Services</h3>	
								
				</div> 
			</div>
			

			<div className="row d-flex align-items-center">

				<div className="col-md-6 col-lg-4">
					
					<div className="sbox-6 pr-30 text-right wow fadeInRight" data-wow-delay="0.4s">
					
						<h4 className="h5-md">Teeth Cleaning</h4>
						<p>Teeth cleaning is part of oral hygiene and involves the removal... </p>
						{/* <p>Diagnosis, prevention and correction of 
							malpositioned teeth and jaws through braces which gives healthy teeth and better smile.</p> */}
						
					</div>
							
					<div className="sbox-6 pr-30 mr-30 text-right wow fadeInRight" data-wow-delay="0.6s">							
							
						<h4 className="h5-sm">Dental Surgery</h4>
						<p>Dental surgery is any of a number of medical procedures...</p>
						{/* <p>Restoration and replacement of lost or damaged teeth by 
							taking support from neighbouring teeth and forming a bridge.</p> */}
						
					</div>	

					<div className="sbox-6 pr-30 text-right wow fadeInRight" data-wow-delay="0.8s">							
						
						<h4 className="h5-md">Orthodontics</h4>
						<p>Orthodontics is a dentistry specialty that addresses the diagnosis...</p>
						{/* <p>A titanium screw acting like a artificial root placed in the Jaw bone in the missing tooth region to provide actual teeth like 
							experience for better esthetics and functions.</p> */}
					</div>

					<div className="sbox-6 pl-30 wow text-right fadeInRight" data-wow-delay="0.4s">
						<h4 className="h5-md">Root Canal</h4>
						<p>Root canal is a treatment to repair and save a badly damaged...</p>
						{/* <p>Cavity or a hole left behind in the tooth due to tooth decay. Removal of the 
							bacteria and treating it with tooth coloured fillings.</p> */}
						
					</div>
						
				</div>	

				<div className="col-lg-4 s6-img">	
					<div className="sbox-6-img mb-40 text-center wow fadeInUp" data-wow-delay="0.8s">
						<img className="img-fluid" src="images/tooth.png" alt="services-image"/>
					</div>
				</div>
		
				<div className="col-md-6 col-lg-4">	

					<div className="sbox-6 pl-30 wow fadeInLeft" data-wow-delay="0.4s">
						
						<h4 className="h5-md">Laser Dentistry</h4>
						<p>Laser dentistry is the use of lasers to treat a number of different dental... </p>
						{/* <p>Cavity or a hole left behind in the tooth due to tooth decay. Removal of the 
							bacteria and treating it with tooth coloured fillings.</p> */}
						
					</div>
					
					<div className="sbox-6 pl-30 ml-30 wow fadeInLeft" data-wow-delay="0.6s">
					
						<h4 className="h5-md">Dental Implant</h4>
						<p>Dental Implants are medical devices surgically implanted into... </p>
						{/* <p>Root canal treatment helps save a tooth that is infected and completely decayed, 
							relieves toothache and gives better tooth functioning.</p> */}
					</div>
						
					<div className="sbox-6 pl-30 wow fadeInLeft" data-wow-delay="0.8s">
						
						<h4 className="h5-md">Tooth Fillings</h4>
						<p>Tooth Fillings, or simply fillings are treatments used to restore the... </p>
						{/* <p>Cleaning of teeth and root surfaces to remove yellow deposits i.e. 
							plaque and calculus. Keeps gums healthy avoiding bad breath, 
							bleeding gums and tooth mobility.</p> */}
					</div>	

					<div className="sbox-6 pl-30 wow fadeInLeft" data-wow-delay="0.4s">
						
						<h4 className="h5-md">Tooth Replacement Solutions</h4>
						<p>If you lose a single tooth, a dental implant is an excellent solution...</p>
						{/* <p>Cavity or a hole left behind in the tooth due to tooth decay. Removal of the 
							bacteria and treating it with tooth coloured fillings.</p> */}
						
					</div>
						
				</div>

			</div>

			<div className="row">	
				<div className="col-lg-10 offset-lg-1 section-title">
					<Link to="/treatments" className="btn tra-white-hover btn-orange ">View All Services</Link> 
				</div> 
			</div>

		</div>	
</section>


<section id="services-7" className="bg-lightgrey wide-70 servicess-section division">
	<div className="container">						
		<div className="row d-flex align-items-center">

			<div className="col-lg-6">
				<div className="services-7-table mb-30 wow fadeInUp" data-wow-delay="0.6s">

				
					<h4 className="h4-xs">Opening Hours:</h4>

					
					{/* <p className="p-sm">Porta semper lacus cursus and feugiat primis ultrice ligula risus auctor
						tempus feugiat and dolor lacinia cursus
					</p> */}

					
					<table className="table">
						<tbody>
							<tr>
								<td>Monday</td>
								<td> - </td>
								<td className="text-right">10:00 AM - 7:00 PM</td>
							</tr>
							<tr>
								<td>Tuesday</td>
								<td> - </td>
								<td className="text-right">10:00 AM - 7:00 PM</td>
							</tr>
							<tr>
								<td>Wednesday</td>
								<td> - </td>
								<td className="text-right">10:00 AM - 7:00 PM</td>
							</tr>
								<tr>
								<td>Thursday</td>
								<td>-</td>
								<td className="text-right">10:00 AM - 7:00 PM</td>
							</tr>
							<tr>
								<td>Friday</td>
								<td>-</td>
								<td className="text-right">10:00 AM - 7:00 PM</td>
							</tr>
							<tr>
								<td>Saturday</td>
								<td> - </td>
								<td className="text-right">10:00 AM - 7:00 PM</td>
							</tr>
							<tr className="last-tr">
								<td>Sunday</td>
								<td>-</td>
								<td className="text-right">By Appointment</td>
								</tr>
							</tbody>
					</table>

					{/* <h5 className="h5-sm">Need a personal health plan?</h5>


					<p className="p-sm">Porta semper lacus cursus, and feugiat primis ultrice ligula at risus auctor</p> */}

				</div>	
			</div>
		

			<div className="col-lg-6 col-xl-6">
				<div id="hero-section-form" className="text-center mb-40">
					<form name="heroForm" className="row hero-form bg-blue">

						<div className="col-md-12 white-color">
							<h4 className="h4-xs">Get an Appointment</h4>	
						</div> 

						<div id="input-name" className="col-lg-12">
							<input type="text" name="name" className="form-control name" placeholder="Enter Your Name*" required/> 
						</div>
								
						<div id="input-email" className="col-lg-12">
							<input type="text" name="email" className="form-control email" placeholder="Enter Your Email*" required/> 
						</div>

						<div id="input-phone" className="col-lg-12">
							<input type="tel" name="phone" className="form-control phone" placeholder="Enter Your Phone Number*" required/> 
						</div>	

						<div id="input-date" className="col-lg-12">
							<input id="datetimepicker" type="text" name="date" className="form-control date" placeholder="Appointment Date" required/> 
						</div>

						<div id="input-doctor" className="col-md-12 input-doctor">
							<select id="inlineFormCustomSelect3" name="patient" className="custom-select patient" required>
								<option value="">Have You Visited Us Before?*</option>
								<option>New Patient</option>
								<option>Returning Patient</option>
								<option>Other</option>
							</select>
						</div>

						<div className="col-lg-12 form-btn">  
							<button type="submit" className="btn btn-orange tra-white-hover submit">Send Your Message</button> 
						</div>

						<div className="col-lg-12 hero-form-msg text-center">
							<div className="sending-msg"><span className="loading"></span></div>
						</div>  
														
					</form>
				</div>
			</div>


		</div>
	</div>
</section>	



{/* <div id="gallery-1" className="gallery-section division">	

				<div className="row gallery-items-list">							

					<div className="col-md-6 col-lg-3 gallery-item">
						<div className="hover-overlay"> 

							<img className="img-fluid" src="images/gallery/1.jpg" alt="gallery-image" />			
							<div className="item-overlay"></div>				

							<div className="image-zoom">
								<a className="image-link" href="images/gallery/1.jpg" title=""><i className="fas fa-search-plus"></i></a>
							</div> 

						</div>
					</div>
										
					<div className="col-md-6 col-lg-3 gallery-item">
						<div className="hover-overlay">

							<img className="img-fluid" src="images/gallery/2.jpg" alt="gallery-image" />			
							<div className="item-overlay"></div>				
			
							<div className="image-zoom">
								<a className="image-link" href="images/gallery/2.jpg" title=""><i className="fas fa-search-plus"></i></a>
							</div> 

						</div>
					</div>
											
					<div className="col-md-6 col-lg-3 gallery-item">
						<div className="hover-overlay"> 

							<img className="img-fluid" src="images/gallery/3.jpg" alt="gallery-image" />			
							<div className="item-overlay"></div>				
										
							<div className="image-zoom">
								<a className="image-link" href="images/gallery/3.jpg" title=""><i className="fas fa-search-plus"></i></a>
							</div> 

						</div>
					</div>
										
					<div className="col-md-6 col-lg-3 gallery-item">
						<div className="hover-overlay"> 

							<img className="img-fluid" src="images/gallery/4.jpg" alt="gallery-image" />			
							<div className="item-overlay"></div>				
									
							<div className="image-zoom">
								<a className="image-link" href="images/gallery/4.jpg" title=""><i className="fas fa-search-plus"></i></a>
							</div> 

						</div>
					</div>
											
					<div className="col-md-6 col-lg-3 gallery-item">
						<div className="hover-overlay"> 

							<img className="img-fluid" src="images/gallery/5.jpg" alt="gallery-image" />			
							<div className="item-overlay"></div>				
	
							<div className="image-zoom">
								<a className="image-link" href="images/gallery/5.jpg" title=""><i className="fas fa-search-plus"></i></a>
							</div> 

						</div>
					</div>

					<div className="col-md-6 col-lg-3 gallery-item">
						<div className="hover-overlay"> 

							<img className="img-fluid" src="images/gallery/6.jpg" alt="gallery-image" />			
							<div className="item-overlay"></div>				
	
							<div className="image-zoom">
								<a className="image-link" href="images/gallery/6.jpg" title=""><i className="fas fa-search-plus"></i></a>
							</div> 

						</div>
					</div>
						
					<div className="col-md-6 col-lg-3 gallery-item">
						<div className="hover-overlay"> 

							<img className="img-fluid" src="images/gallery/7.jpg" alt="gallery-image" />			
							<div className="item-overlay"></div>				
									
							<div className="image-zoom">
								<a className="image-link" href="images/gallery/7.jpg" title=""><i className="fas fa-search-plus"></i></a>
							</div> 

						</div>
					</div>
										
					<div className="col-md-6 col-lg-3 gallery-item">
						<div className="hover-overlay"> 

							<img className="img-fluid" src="images/gallery/8.jpg" alt="gallery-image" />			
							<div className="item-overlay"></div>				
									
							<div className="image-zoom">
								<a className="image-link" href="images/gallery/8.jpg" title=""><i className="fas fa-search-plus"></i></a>
							</div> 

						</div>
					</div>	
				</div>
</div> */}


<div id="gallery-1" className="gallery-section division">	
	<div className="row gallery-items-list App">
				
		{arrOfImages.map((image, index) => (
			<div className="col-md-6 col-lg-3 gallery-item">
				<img
				key={image}
				className="img-fluid"
				src={image}
				alt={image}
				onClick={() => openModalAndSetIndex(index)}
				/>
			</div>
			))}
		
			{showModal && (
				<Lightbox
				mainSrc={arrOfImages[indexOfImages]}
				nextSrc={arrOfImages[(indexOfImages + 1) % arrOfImages.length]}
				prevSrc={
					arrOfImages[
					(indexOfImages + arrOfImages.length - 1) % arrOfImages.length
					]
				}
				onCloseRequest={() => setShowModal(false)}
				onMovePrevRequest={() =>
					setIndexOfImages(
					(indexOfImages + arrOfImages.length - 1) % arrOfImages.length
					)
				}
				onMoveNextRequest={() =>
					setIndexOfImages(
					(indexOfImages + arrOfImages.length + 1) % arrOfImages.length
					)
				}
				/>
			)}

	</div>
</div>


      
    </>
  )
}

export default Home
