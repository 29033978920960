import React from 'react'

const Contact = () => {
  return (
    <>
      <section id="contacts-1" className="wide-60 contacts-section division">				
				<div className="container">

					<div className="row">	
						<div className="col-lg-10 offset-lg-1 section-title">	

							<h3 className="h3-md steelblue-color">Have a Question? Get In Touch</h3>	

							<p>Have a question? Want to book an appointment for yourself or your children? Give us a call
							   or send an email to contact the Dental Planet Multisolutions.
							</p>
								
						</div>
					</div>

						
					<div className="row">	

		 				<div className="col-sm-12 col-md-4 col-lg-3 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Head Office</h5>
								<p>Dental Planet Multisolutions</p> 
								<p>506-A, Corporate House, Block-A,</p>
								<p>Near Central Mall, RNT Marg, Indore-452001</p>
		 					</div>
						</div>

		 				<div className="col-sm-12 col-md-4 col-lg-3 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Branch</h5>
								<p>Dental Planet Multisolutions</p> 
								<p>171-172 Anand Niketan, Scheme No.114</p>
								<p>Part 2, Vijay Nagar, Indore-452001</p>
		 					</div>
						</div>

						<div className="col-sm-12 col-md-4 col-lg-3 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Charitable Office</h5>
								<p>Manikbag Center</p> 
								<p>M/43, Joshi Gali, Near Babji Kirana,</p>
								<p>Khatiwala tank, Indore-452001</p>
		 					</div>
						</div>

						<div className="col-sm-12 col-md-4 col-lg-3 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Charitable Office</h5>
								<p>Geeta Bhavan Center</p> 
								<p>7- C , Seth Apartment,</p>
								<p>Kailash Park Colony, Indore-452001</p>
		 					</div>
						</div>

						<div className="col-sm-12 col-md-4 col-lg-4 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Charitable Office</h5>
								<p>Chameli Devi, Chhawni Center</p> 
								<p>Chhawni Hatt Maidan, Near Agrawal Kanya Vidhyalay,</p>
								<p>Indore-452001</p>
		 					</div>
						</div>

		 				<div className="col-sm-12 col-md-4 col-lg-4 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Charitable Office</h5>
								<p>Marimata Center</p> 
								<p>31 Khasgi Ka Bagicha, 15 Battalion Road,</p>
								<p>Near Garima High School, Indore-452001</p>
		 					</div>
						</div>

		 				<div className="col-sm-12 col-md-4 col-lg-4 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Charitable Office</h5>
								<p>Gandhi Nagar Center</p> 
								<p>162,163 Bahubali Nagar,Gandhi Nagar,</p>
								<p>Main Road, Indore-452001</p>
		 					</div>
						</div>

		 				<div className="col-sm-12 col-md-6 col-lg-6 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Contact</h5>
								<p>Phone: <a href="tel:+91 7974303995">+91 7974303995</a></p>
								<p>Tel: <a href="tel:07314900922">0731-4900922</a></p>
		 					</div>
						</div>
							

		 				<div className="col-sm-12 col-md-6 col-lg-6 border pt-5">
		 					<div className="contact-box mb-40">
								<h5 className="h5-sm steelblue-color">Email</h5>
								<p><a href="mailto:dentalplanetmultisolutions@gmail.com" className="blue-color">dentalplanetmultisolutions@gmail.com</a></p>
                                <p><a href="mailto:planetdental.ms@gmail.com" className="blue-color">planetdental.ms@gmail.com</a></p>
		 					</div>
						</div>

				 		<div className="col-md-12 col-lg-12 border pt-5">
				 			<div className="form-holder mb-40">
								
				 				<form name="contactForm" className="row contact-form">
				                                            
					                <div id="input-name" className="col-md-12 col-lg-6">
					                	<input type="text" name="name" className="form-control name" placeholder="Enter Your Name*" required/> 
					                </div>
					                        
					                <div id="input-email" className="col-md-12 col-lg-6">
					                	<input type="text" name="email" className="form-control email" placeholder="Enter Your Email*" required/> 
					                </div>

					                <div id="input-phone" className="col-md-12 col-lg-6">
					                	<input type="tel" name="phone" className="form-control phone" placeholder="Enter Your Phone Number*" required/> 
					                </div>	

					                <div id="input-patient" className="col-md-12 col-lg-6 input-patient">
					                    <select id="inlineFormCustomSelect3" name="patient" className="custom-select patient" required>
					                        <option value="">Have You Visited Us Before?*</option>
											<option>New Patient</option>
											<option>Returning Patient</option>
											<option>Other</option>
					                    </select>
					                </div>

					                <div id="input-subject" className="col-lg-12">
					                	<input type="text" name="subject" className="form-control subject" placeholder="Subject*" required/> 
					                </div>					                          
					                        
					                <div id="input-message" className="col-lg-12 input-message">
					                	<textarea className="form-control message" name="message" rows="6" placeholder="Enquiry Message* ..." required></textarea>
					                </div> 
					                                            
					                <div className="col-lg-12 mt-15 form-btn">  
					                	<button type="submit" className="btn btn-blue blue-hover submit">Submit details</button> 
					                </div>
					                                                              
					                <div className="col-lg-12 contact-form-msg text-center">
					                	<div className="sending-msg"><span className="loading"></span></div>
					                </div>  
				                                              
				                </form> 

				 			</div>	
				 		</div>

				 	</div>

				</div>
			</section>

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.3619235135347!2d75.8718275852261!3d22.71725455121831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd16c72ec579%3A0x109c709ad7e1b333!2sCorporate%20House%2C%20RNT%20Marg%2C%20Chhoti%20Gwaltoli%2C%20Indore%2C%20Madhya%20Pradesh%20452001!5e0!3m2!1sen!2sin!4v1665992898285!5m2!1sen!2sin" 
          width="100%"
          height="450" 
          style={{border:0}} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">

      </iframe>
    
    </>
  )
}

export default Contact